<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g stroke="#F93" stroke-width="1.5">
        <g>
          <path
            d="M.009 5.994L14.998 5.994M6.012 0L0 6.012 6.012 12.023"
            transform="translate(-445.000000, -224.000000) translate(445.000000, 224.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPictoAarrowsLeft",
};
</script>

<style></style>
